/*
Template Name: Tailwick - Admin & Dashboard Template
Author: Themesdesign
Version: 1.1.0
Website: https://themesdesign.in/
Contact: Themesdesign@gmail.com
File: tailwind scss File
*/

@import "fonts/fonts";

@tailwind base;
@tailwind components;
@tailwind utilities;

@import "plugins/gridjs";
@import "plugins/select";
@import "plugins/multi-select";



.group\/menu-link:hover,
.animate {
   animation-iteration-count: 2;
   stroke-dasharray: 10;
}

*{
   font-family: 'Open Sans', sans-serif;
   
}
h1, h2, h3, h4, h5, h6, button {
  font-family: 'Cairo', sans-serif;
  font-weight: 700;
}
